/* eslint-disable remix-react-routes/use-link-for-routes */
import { useState } from 'react'
import type { V2_MetaFunction } from '@remix-run/node'
import { AnimatePresence, motion } from 'framer-motion'
import { Dialog } from '@headlessui/react'
import {
	// CalendarDaysIcon,
	// CalendarDaysIcon,
	// CalendarDaysIcon,
	MicrophoneIcon,
	VideoCameraIcon,
} from '@heroicons/react/20/solid'

export const meta: V2_MetaFunction = () => [{ title: 'Hope Point Church' }]

const TRANSITIONS = {
	DURATION: 0.5,
	EASE: [0.32, 0.72, 0, 1],
}

export default function Index() {
	let [openLatestMessages, setOpenLatestMessages] = useState(false)
	let [openGiving, setOpenGiving] = useState(false)
	let [openNextSteps, setOpenNextSteps] = useState(false)
	let [openUpcomingEvents, setOpenUpcomingEvents] = useState(false)

	return (
		<div className="mx-auto max-w-xl">
			{/* <h2 className="pb-4 pl-6 font-display text-2xl font-semibold tracking-widest">
				What's Happening
			</h2> */}
			<div className="relative mb-6 overflow-hidden text-black">
				<div className="mx-auto max-w-xl">
					<div className="container-snap flex snap-x snap-mandatory space-x-8 overflow-x-scroll">
						<div className="relative ml-4 h-64 w-72 shrink-0 snap-center rounded-xl bg-[#d8bfa1] sm:ml-12 sm:h-[22rem] sm:w-[28rem]">
							<h2 className="px-4 pt-3 text-lg font-bold sm:text-xl">
								Joining us for the first time?
							</h2>
							<p className="px-4 py-1.5">We are so glad you are here!</p>
							<p className="px-4 py-1.5">
								Click below to get connected with Hope Point.
							</p>
							<div className="flex justify-center pt-2">
								<a
									href="https://hopepointchurch.churchcenter.com/people/forms/107297"
									target="_blank"
									rel="noreferrer"
								>
									<button
										type="button"
										className="rounded-lg border-2 border-black bg-[#e8ddcb] px-3.5 py-2 font-display text-lg font-bold hover:bg-opacity-90"
									>
										New Here
									</button>
								</a>
							</div>
						</div>
						<div className="h-68 w-72 shrink-0 snap-center rounded-xl bg-[#e8ddcb] sm:h-[22rem] sm:w-[28rem]">
							<h2 className="px-4 pt-3 text-lg font-bold sm:text-xl">
								I have decided!
							</h2>
							<p className="px-4 py-1.5">Just say a prayer of salvation?</p>
							{/* <p className="px-4 py-1.5">Let us know!</p> */}
							<div className="flex justify-center pt-12">
								<a
									href="https://hopepointchurch.churchcenter.com/people/forms/555152"
									target="_blank"
									rel="noreferrer"
								>
									<button
										type="button"
										className="rounded-lg border-2 border-black bg-[#ded9d3] px-3.5 py-2 font-display text-lg font-bold hover:bg-opacity-90"
									>
										Let us know
									</button>
								</a>
							</div>
						</div>
						<div className="h-68 w-72 shrink-0 snap-center rounded-xl bg-[#ded9d3] sm:h-[22rem] sm:w-[28rem]">
							<h2 className="px-4 pt-3 text-lg font-bold sm:text-xl">
								Hope Cares
							</h2>
							<p className="px-4 py-1.5">Get involved in our community.</p>
							<div className="flex justify-center pt-12">
								<a
									href="https://hopepointchurch.org/hopecares"
									target="_blank"
									rel="noreferrer"
								>
									<button
										type="button"
										className="rounded-lg border-2 border-black bg-[#e8ddcb] px-3.5 py-2 font-display text-lg font-bold hover:bg-opacity-90"
									>
										Learn More
									</button>
								</a>
							</div>
						</div>
						<div className="h-68 w-80 shrink-0 snap-center rounded-xl bg-[#d8bfa1] sm:h-[22rem] sm:w-[28rem]">
							<h2 className="px-4 pt-3 text-lg font-bold sm:text-xl">
								Join us on social
							</h2>
							<div className="flex justify-center gap-x-4 pt-12">
								<a
									href="https://www.facebook.com/hopepointva"
									target="_blank"
									rel="noreferrer"
								>
									<span className="sr-only">Facebook</span>
									<svg
										className="h-12 w-12"
										fill="currentColor"
										viewBox="0 0 24 24"
									>
										<path
											fillRule="evenodd"
											d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
											clipRule="evenodd"
										/>
									</svg>
								</a>
								<a
									href="https://www.instagram.com/hopepointva/"
									target="_blank"
									rel="noreferrer"
								>
									<span className="sr-only">Instagram</span>
									<svg
										className="h-12 w-12"
										fill="currentColor"
										viewBox="0 0 24 24"
									>
										<path
											fillRule="evenodd"
											d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
											clipRule="evenodd"
										/>
									</svg>
								</a>
								<a
									href="https://www.youtube.com/channel/UC3aFwajgm4Yxh8xvGGgVYPg "
									target="_blank"
									rel="noreferrer"
								>
									<span className="sr-only">YouTube</span>
									<svg
										className="h-12 w-12"
										viewBox="0 0 50 50"
										fill="currentColor"
									>
										<path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z" />
									</svg>
								</a>
							</div>
						</div>
						<div className="relative ml-4 h-64 w-72 shrink-0 snap-center rounded-xl bg-[#e8ddcb] sm:ml-12 sm:h-[22rem] sm:w-[28rem]">
							<h2 className="px-4 pt-3 text-lg font-bold sm:text-xl">
								Website Link
							</h2>
							<p className="px-4 py-1.5">
								Need more information? Visit our full website.
							</p>
							<div className="flex justify-center pt-2">
								<a
									href="https://hopepointchurch.org"
									target="_blank"
									rel="noreferrer"
								>
									<button
										type="button"
										className="rounded-lg border-2 border-black bg-[#e8ddcb] px-3.5 py-2 font-display text-lg font-bold hover:bg-opacity-90"
									>
										hopepointchurch.org
									</button>
								</a>
							</div>
						</div>
						<div className="h-68 w-80 shrink-0 snap-center rounded-xl bg-black text-white sm:h-[22rem] sm:w-[28rem]">
							<h2 className="px-4 pt-3 text-lg font-bold sm:text-xl">
								Add us to your home screen
							</h2>
							<p className="px-4 pt-10">
								Tap{' '}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="inline-block h-6 w-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
									/>
								</svg>{' '}
								at the bottom of your mobile browser and then tap "Add to Home
								Screen"
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="relative flex flex-col items-center justify-center bg-[#ded9d3] text-black sm:bg-[#ededea]">
				<div className="w-full">
					<button
						onClick={() => setOpenLatestMessages(true)}
						className="w-full"
					>
						<div className="h-28 rounded-t-xl bg-black text-white">
							<div className="mx-auto h-1 w-12 translate-y-2 bg-gray-100 opacity-20" />
							<h2 className="pt-5 font-bold uppercase tracking-widest">
								Latest Message
							</h2>
						</div>
					</button>
					<button
						onClick={() => setOpenGiving(true)}
						className="w-full -translate-y-12 pb-3"
					>
						<div className="h-28 rounded-t-xl bg-[#e8ddcb]">
							<div className="mx-auto h-1 w-12 translate-y-2 bg-black opacity-10" />
							<h2 className="pt-5 font-bold uppercase tracking-widest">
								Giving
							</h2>
						</div>
					</button>
					<button
						onClick={() => setOpenNextSteps(true)}
						className="w-full -translate-y-28"
					>
						<div className="h-28 rounded-t-xl bg-[#d8bfa1]">
							<div className="mx-auto h-1 w-12 translate-y-2 bg-black opacity-10" />
							<h2 className="pt-5 font-bold uppercase tracking-widest">
								Next Steps
							</h2>
						</div>
					</button>
					<button
						onClick={() => setOpenUpcomingEvents(true)}
						className="w-full -translate-y-40"
					>
						<div className="h-28 rounded-t-xl bg-[#ded9d3] sm:h-20">
							<div className="mx-auto h-1 w-12 translate-y-2 bg-black opacity-10" />
							<h2 className="pt-5 font-bold uppercase tracking-widest">
								Upcoming Events
							</h2>
						</div>
					</button>
				</div>
				<AnimatePresence>
					{openLatestMessages && (
						<ShowLatestMessages onClose={() => setOpenLatestMessages(false)} />
					)}
					{openGiving && <ShowGiving onClose={() => setOpenGiving(false)} />}
					{openNextSteps && (
						<ShowNextSteps onClose={() => setOpenNextSteps(false)} />
					)}
					{openUpcomingEvents && (
						<ShowUpcomingEvents onClose={() => setOpenUpcomingEvents(false)} />
					)}
				</AnimatePresence>
			</div>
		</div>
	)
}

function ShowLatestMessages({ onClose }: { onClose: () => void }) {
	return (
		<Modal onClose={onClose} classNames={['bg-black']}>
			<div className="flex h-full flex-col pt-3 text-white">
				<div className="px-3 pb-4 shadow-sm shadow-gray-800">
					<div className="relative mt-5 text-center">
						<span className="font-bold uppercase tracking-widest">
							Latest Message
						</span>
						<div className="absolute inset-y-0 right-0">
							<button className="mr-1 focus:outline-none" onClick={onClose}>
								Close
							</button>
						</div>
					</div>
				</div>

				<div className="flex-1 overflow-y-scroll">
					{/* Title */}
					<h3 className="pt-5 text-3xl font-semibold tracking-widest text-white">
						The Bold and the Beautiful
					</h3>
					{/* Subtitle */}
					{/* <h4 className="py-2 font-display font-semibold tracking-widest text-white">
						From Series: "Awe of God"
					</h4> */}
					{/* Author */}
					<h5 className=" font-semibold italic tracking-widest text-white">
						Jon Cain
					</h5>
					<div className="flex justify-center gap-x-8 pt-12">
						<a
							href="https://www.youtube.com/watch?v=9yxP9EwqmWo"
							target="_blank"
							rel="noreferrer"
						>
							<span className="sr-only">Watch Video</span>
							<VideoCameraIcon className="h-12 w-12" />
						</a>
						<a
							href="https://hopepointchurch.org/hope-messages/"
							target="_blank"
							rel="noreferrer"
						>
							<span className="sr-only">Listen to Podcast</span>
							<MicrophoneIcon className="h-12 w-12" />
						</a>
					</div>
					<p className="px-4 pt-8 text-sm">
						Watch or listen to our latest message.
					</p>
				</div>
			</div>
		</Modal>
	)
}
function ShowGiving({ onClose }: { onClose: () => void }) {
	return (
		<Modal onClose={onClose} classNames={['bg-[#e8ddcb]']}>
			<div className="flex h-full flex-col pt-3 text-black">
				<div className="px-3 pb-4 shadow-sm">
					<div className="relative mt-5 text-center">
						<span className="font-bold uppercase tracking-widest">Giving</span>
						<div className="absolute inset-y-0 right-0">
							<button className="mr-1 focus:outline-none" onClick={onClose}>
								Close
							</button>
						</div>
					</div>
				</div>

				<div className="flex-1 overflow-y-scroll">
					<h3 className="px-8 pt-5 text-left text-3xl font-semibold tracking-wide">
						Your giving makes a difference!
					</h3>
					<p className="px-8 py-4 text-left">
						Giving is at the heart of God and we are confident that you will
						discover that He refuses to be outgiven! Your gift helps us expand
						the Kingdom of God and reach as many as we can for Jesus Christ.
						Your financial contribution helps us to do exactly that.
					</p>
					<div className="flex justify-center pt-2">
						<a
							href="https://pushpay.com/g/hopepointchurch?src=hp"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="rounded-lg border-2 border-black bg-[#ededea] px-6 py-2 font-display text-2xl font-extrabold tracking-widest hover:bg-opacity-90"
							>
								Give
							</button>
						</a>
					</div>
				</div>
			</div>
		</Modal>
	)
}
function ShowNextSteps({ onClose }: { onClose: () => void }) {
	return (
		<Modal onClose={onClose} classNames={['bg-[#d8bfa1]']} title="Next Steps">
			<div className="flex h-full flex-col pt-3 text-black">
				<div className="px-3 pb-4 shadow-sm">
					<div className="relative mt-5 text-center">
						<span className="font-bold uppercase tracking-widest">
							Next Steps
						</span>
						<div className="absolute inset-y-0 right-0">
							<button className="mr-1 focus:outline-none" onClick={onClose}>
								Close
							</button>
						</div>
					</div>
				</div>

				<div className="flex-1 overflow-y-scroll pb-24">
					<h3 className="px-8 pt-5 text-left text-3xl font-semibold tracking-wide">
						I have decided!
					</h3>
					<p className="px-8 py-6 text-left">
						We want to connect with you and walk you through your journey. Let
						us know that you made that decision.
					</p>
					<div className="flex justify-center pt-2">
						<a
							href="https://hopepointchurch.churchcenter.com/people/forms/555152"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="rounded-lg border-2 border-black bg-[#e8ddcb] px-6 py-2 font-display text-xl font-extrabold tracking-widest hover:bg-opacity-90"
							>
								I made a decision
							</button>
						</a>
					</div>
					<h3 className="mt-8 border-t border-[#e8ddcb] px-8 pt-8 text-left text-3xl font-semibold tracking-wide">
						Baptism
					</h3>
					<p className="px-8 py-6 text-left">
						Baptism is going public about your faith in Jesus and communicating
						your heartfelt commitment to following Him. If you would like to get
						baptized at Hope Point Church, sign up today!
					</p>
					<div className="flex justify-center pt-2">
						<a
							href="https://hopepointchurch.churchcenter.com/unproxy/registrations/events/category/81583"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="rounded-lg border-2 border-black bg-[#e8ddcb] px-6 py-2 font-display text-xl font-extrabold tracking-widest hover:bg-opacity-90"
							>
								Sign up
							</button>
						</a>
					</div>
					<h3 className="mt-8 border-t border-[#e8ddcb] px-8 pt-8 text-left text-3xl font-semibold tracking-wide">
						Journey
					</h3>
					<p className="px-8 py-6 text-left">
						Get to know people, meet our pastors, and learn a little more about
						Hope Point Church.
					</p>
					<div className="flex justify-center pt-2">
						<a
							href="https://hopepointchurch.churchcenter.com/registrations/events/category/81625"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="rounded-lg border-2 border-black bg-[#e8ddcb] px-6 py-2 font-display text-xl font-extrabold tracking-widest hover:bg-opacity-90"
							>
								The Journey
							</button>
						</a>
					</div>
					<h3 className="mt-8 border-t border-[#e8ddcb] px-8 pt-8 text-left text-3xl font-semibold tracking-wide">
						Foundations
					</h3>
					<p className="px-8 py-6 text-left">
						Foundations is a class designed to teach you eight fundamental
						principles that will help you build well in your faith, all while
						connecting you with others who are trying to do the same. Register
						by clicking the link below as we get started on building well
						together!
					</p>
					<div className="flex justify-center pt-2">
						<a
							href=" https://hopepointchurch.churchcenter.com/groups/connect-groups?enrollment=open_signup%2Crequest_to_join&filter=enrollment%2Ctag&tag_id=1965766"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="rounded-lg border-2 border-black bg-[#e8ddcb] px-6 py-2 font-display text-xl font-extrabold tracking-widest hover:bg-opacity-90"
							>
								Foundations
							</button>
						</a>
					</div>
					<h3 className="mt-8 border-t border-[#e8ddcb] px-8 pt-8 text-left text-3xl font-semibold tracking-wide">
						Connect Groups
					</h3>
					<p className="px-8 py-6 text-left">
						You weren’t meant to do life alone; we believe we are better
						together. Check out all our groups!
					</p>
					<div className="flex justify-center pt-2">
						<a
							href="https://hopepointchurch.org/hubs/"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="rounded-lg border-2 border-black bg-[#e8ddcb] px-6 py-2 font-display text-xl font-extrabold tracking-widest hover:bg-opacity-90"
							>
								Get Connected
							</button>
						</a>
					</div>
				</div>
			</div>
		</Modal>
	)
}
function ShowUpcomingEvents({ onClose }: { onClose: () => void }) {
	return (
		<Modal
			onClose={onClose}
			classNames={['bg-[#ded9d3]']}
			title="Upcoming Events"
		>
			<div className="flex h-full flex-col pt-3 text-black">
				<div className="px-3 pb-4 shadow-sm">
					<div className="relative mt-5 text-center">
						<span className="font-bold uppercase tracking-widest">
							Upcoming Events
						</span>
						<div className="absolute inset-y-0 right-0">
							<button className="mr-1 focus:outline-none" onClick={onClose}>
								Close
							</button>
						</div>
					</div>
				</div>

				<div className="flex-1 overflow-y-scroll pb-96">
					<div className="grid grid-cols-12 divide-x-4 divide-black px-4 py-4">
						<div className="col-span-2 space-y-1 pr-1.5 text-[10px] text-stone-600">
							<div>Recurring</div>
							{/* <div>7:00pm</div> */}
						</div>
						<div className="col-span-10 space-y-1 pl-2 text-left">
							<div className="flex items-center justify-between">
								<div className="font-bold">Stepping Stones</div>
								{/* <a href="/calendar/coming-in-hot.ics" download={true}>
									<CalendarDaysIcon className="h-5 w-5" />
								</a> */}
							</div>
							<div className="text-xs text-stone-600">
								Hope Kids Family, we know that in our relationship with Jesus,
								there are many stepping stones to having a solid foundation in
								him. We want to partner with you to ensure your kids have a
								strong relationship with Jesus; Stepping Stones is a salvation &
								baptism class for kids who are ready to take the next step in
								their relationship with Jesus. If you are interested in signing
								your child up, click the link below.
							</div>
							<a
								href=" https://hopepointchurch.churchcenter.com/people/forms/584989"
								target="_blank"
								rel="noreferrer"
							>
								<button className="mt-3 rounded-lg border border-black px-3.5 py-1.5 text-xs font-bold uppercase">
									Sign Up
								</button>
							</a>
						</div>
					</div>

					<div className="grid grid-cols-12 divide-x-4 divide-black px-4 py-4">
						<div className="col-span-2 space-y-1 pr-1.5 text-[10px] text-stone-600">
							<div>Apr. 11</div>
							{/* <div>12:45pm</div> */}
						</div>
						<div className="col-span-10 space-y-1 pl-2 text-left">
							<div className="flex items-center justify-between">
								<div className="font-bold">Candy Donations</div>
								{/* <a href="/calendar/first-wed-march-25.ics" download={true}>
									<CalendarDaysIcon className="h-5 w-5" />
								</a> */}
							</div>
							<div className="text-xs text-stone-600">
								Help us make this year’s Easter Egg Hunt extra sweet! We’re
								collecting candy donations and we've made it simple for you by
								creating an Amazon wishlist. All candy needs to be to the church
								by Friday, April 11th. Thank you for helping us create a fun and
								joyful event!
							</div>
							<a
								href="https://www.amazon.com/hz/wishlist/ls/Q92JGYGGLNSE?ref_=wl_share"
								target="_blank"
								rel="noreferrer"
							>
								<button className="mt-3 rounded-lg border border-black px-3.5 py-1.5 text-xs font-bold uppercase">
									Amazon Wishlist
								</button>
							</a>
						</div>
					</div>
					<div className="grid grid-cols-12 divide-x-4 divide-black px-4 py-4">
						<div className="col-span-2 space-y-1 pr-1.5 text-[10px] text-stone-600">
							<div>Apr. 28</div>
							<div>1:00pm</div>
						</div>
						<div className="col-span-10 space-y-1 pl-2 text-left">
							<div className="flex items-center justify-between">
								<div className="font-bold">Hope Cares Golf Tournament</div>
								{/* <a href="/calendar/first-wed-march-25.ics" download={true}>
									<CalendarDaysIcon className="h-5 w-5" />
								</a> */}
							</div>
							<div className="text-xs text-stone-600">
								Join us for our annual Golf Tournament at Highlands Golf Course
								on Monday, April 28th. The cost is $125.00 per person or $450.00
								for a team and all proceeds go to Hope Cares. For all the
								information you need, click the link below.
							</div>
							<a
								href="https://hopepointchurch.org/golf/"
								target="_blank"
								rel="noreferrer"
							>
								<button className="mt-3 rounded-lg border border-black px-3.5 py-1.5 text-xs font-bold uppercase">
									Information
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

function Modal({
	onClose,
	classNames,
	children,
	title,
}: {
	onClose: () => void
	classNames: string[]
	children: any
	title?: string
}) {
	return (
		<Dialog
			className="fixed inset-0 z-10 mx-auto max-w-xl"
			onClose={onClose}
			open={true}
			static
		>
			<div className="flex h-full flex-col justify-center pt-4 text-center sm:block sm:p-0">
				<Dialog.Overlay
					as={motion.div}
					variants={{
						open: {
							opacity: 1,
							transition: {
								ease: TRANSITIONS.EASE,
								duration: 2,
							},
						},
						closed: {
							opacity: 0,
							transition: {
								ease: TRANSITIONS.EASE,
								duration: TRANSITIONS.DURATION,
							},
						},
					}}
					initial={{ opacity: 0 }}
					animate={{
						opacity: 1,
						transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
					}}
					exit={{
						opacity: 0,
						transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
					}}
					// className="fixed inset-0 bg-black"
				/>

				<motion.div
					initial={{ y: '100%' }}
					animate={{
						y: 0,
						transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
					}}
					exit={{
						y: '100%',
						transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
					}}
					className={`${
						title ? 'mt-32' : 'mt-16'
					} z-0 flex h-full w-full flex-col rounded-t-xl sm:mt-20 ${classNames.join(
						' ',
					)}`}
				>
					{children}
				</motion.div>
			</div>
		</Dialog>
	)
}
